<!--
 * @Author: lzh
 * @Date: 2022-07-05 15:21:01
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 14:08:30
 * @Description: file content
-->
<template>
  <div
    class="try-to-contact-box px-try-to-contact"
    :class="`edit_${Xindex}_title`"
    @click="clickItem('title')"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      @click="clickItem('desc')"
      :class="`edit_${Xindex}_desc`"
      :style="`text-align: ${configs.descAlign};`"
    ></h2>
    <div class="btn-box">
      <selfButton
        v-for="(item, index) in configs.btns"
        :key="index"
        :theme="index == 0 ? '' : 'plain'"
        :go="item.go"
        :class="`btn  btn${index + 1}`"
      >{{item[`${nowL}title`] || item.title}}</selfButton>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: 'self-try-to-contact',
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          btns: [
            {
              title: '預約演示',
              type: 'default',
            },
            {
              title: '查看詳情',
              type: 'plain',
            },
          ],
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: '',
      showOffer: false
    };
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      } else {
        let btn = this.configs.btns[x]
        this.$util.route.go(btn.go)
      }
    }
  },
};
</script>

<style lang="less" scoped>
.try-to-contact-box {
  padding: 90px 0;
  font-family: PingFang SC-Medium, PingFang SC;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 40px;
    font-weight: 700;
    color: #000000;
    text-align: center;
  }
  .desc {
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-top: 16px;
  }
  .btn-box {
    display: flex;
    align-items: center;
    margin-top: 52px;
    justify-content: center;
    & /deep/ .self-button-box {
      font-weight: 700;
      &:nth-child(2) {
        margin-left: 40px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .px-try-to-contact {
    padding: 35px 22px;
    .title {
      font-size: 15px;
      font-weight: 700;
      color: #000000;
    }
    .desc {
      font-size: 12px;
    }
    .btn-box {
      margin-top: 30px !important;
      .self-button-box {
        &:nth-child(2) {
          margin-left: 20px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .px-try-to-contact {
    padding: 35px 22px;
    .title {
      font-size: 15px;
      font-weight: 500;
      color: #000000;
    }
    .desc {
      font-size: 12px;
    }
    .btn-box {
      margin-top: 15px !important;
      .self-button-box {
        &:nth-child(2) {
          margin-left: 20px !important;
        }
      }
    }
  }
}
</style>
